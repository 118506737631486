import React from 'react';

const Bottomsection = () => (
  <div>
	    <div className="main-sec-container">

		<div className="sec-container sec-follow">
			<div className="container">
				<div className="row">
					<div className="col-md-4">
						<div className="media">							
							<div className="ts-icon mr-3">
								<i className="fas fa-globe"></i>
							</div>
							<div className="media-body">
								<h5 className="mt-0 fw-600">Contrib Marketplace</h5>
								<p>
									Browse Jobs, Ideas and Micro Tasks. 
								</p>
							</div>
						</div>
					</div>
					<div className="col-md-4">
						<div className="media">							
							<div className="ts-icon mr-3">
								<i className="fas fa-globe"></i>
							</div>
							<div className="media-body">
								<h5 className="mt-0 fw-600">Contribute</h5>
								<p>
									Contribute using your skills, services, apps and/or capital. 
								</p>
							</div>
						</div>
					</div>
					<div className="col-md-4">
						<div className="media">							
							<div className="ts-icon mr-3">
								<i className="fas fa-globe"></i>
							</div>
							<div className="media-body">
								<h5 className="mt-0 fw-600">Crypto Marketplace</h5>
								<p>
									Contribute to blockchain projects on premium urls today 
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="section-feat-intro sec-stats">
			<div className="container feat-intro shadow-1 p-60px">
				<div className="row">
					<div className="col-sm-12">
						<ul className="row">
							<li className="col">
								<div className="no-of-stats">5250</div>
								<div>NO. OF MEMBERS</div>
							</li>
							<li className="col">
								<div className="no-of-stats">310</div>
								<div>NO. OF CAMPAIGNS</div>
							</li>
							<li className="col">
								<div className="no-of-stats">395</div>
								<div>REWARDS GIVEN</div>
							</li>
							<li className="col">
								<div className="no-of-stats">3599072</div>
								<div>TOTAL IMPRESSIONS</div>
							</li>							
						</ul>
					</div>
				</div>
			</div>
		</div>
		
	</div>
  </div>
)
 
export default Bottomsection;