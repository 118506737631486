import React from 'react';

const Midsection = () => (
  <div>
	    <div className="main-sec-container">

		<div className="sec-container sec-follow">
			<div className="container">
				<div className="row p-80px-t sm-p-50px-t feature-bottom align-items-center">
				    <div className="col-md-6 col-sm-12">
					 <img className="img-fluid" src="./img/why-us.png" alt=""></img>
				    </div>
				    <div className="col-md-6 col-sm-12">
					 <h2 className="sec-title fw-800 mb-4">Follow, Build, and Help Launch</h2>
					 <h5>
						<span className="fw-600">Follow <span className="text-default">{window.Configs.domain}</span></span> and other great ventures on the Contrib platform. 
					</h5>						
					 <h5>
						<span className="fw-600">Build <span className="text-default">{window.Configs.domain}</span></span> and Help cofound a relevant new Startup, Part-Time.
					 </h5>
					 <h5>
						<span className="fw-600">Launch <span className="text-default">{window.Configs.domain}</span></span> and you could be front and center in the process. 
						Launch <span className="text-default">domain.com</span> with us today! 
					</h5>
					 <div className="mt-4">
						<a href={'https://www.contrib.com/signup/follow/'+window.Configs.domain} target="_blank" className="btn btn-primary btn-lg" rel="noopener noreferrer">Learn About {window.Configs.domain}</a>
					 </div>
				    </div>
				</div>				
			</div>
		</div>
		
		<div className="sec-container sec-services bg-light">
			<div className="container">
			    <div className="row">
				 <div className="col-sm-12 mb-5">
					<div className="section-title-1 text-center">
						<h2 className="sec-title fw-800 text-uppercase">{window.Configs.domain} team</h2>
					</div>
				 </div>
			    </div>
			    <div className="row align-items-center">
				 <div className="col-lg-4 col-md-12">
				     <div className="service-description">
					  <h3 className="fw-800">Our Awesome Team that Help You to Make Right Choice</h3>
				     </div>
				     <div className="single-service mt-5">					  
					  <i className="fas fa-users text-primary"></i>
					  <p className="mt-3"><span className="fw-600 text-default">{window.Configs.domain}</span> is a bit different than most startups. We are small, diverse team working remotely and loving what we do. We only cowork with others who also have this same passion. </p>
				     </div>
				 </div>
				 <div className="col-lg-8 col-md-12">
				     <div className="row">
					  <div className="col-lg-6 col-md-12">
					      <div className="single-service mt-5 mb-5">
						   <i className="fas fa-users-cog text-primary"></i>
						   <p className="mt-3"><span className="fw-600 text-default">{window.Configs.domain}</span> seeks to contract and hire the best people and then trust them: it's the thinking behind the work at their own time policy. </p>
					      </div>
					  </div>
					  <div className="col-lg-6 col-md-12 col-sm-12">
					      <div className="single-service">
						   <i className="fas fa-cogs text-primary"></i>
						   <p className="mt-3">The <span className="fw-600 text-default">{window.Configs.domain}</span> team loves building things and focus on being the most productive individual, not the amount of time spent in the office. </p>
					      </div>
					  </div>
					  <div className="col-sm-12">
					      <p className="mt-3 font-lora">We put a lot of effort into making <span className="text-default">{window.Configs.domain}</span> a fun place to work for people who like getting things done. So if you're game with this then enter your email address and be a part of the global team. </p>
					  </div>
				     </div>
				 </div>
			    </div>
			</div>
		</div>
		
	</div>
  </div>
)
 
export default Midsection;