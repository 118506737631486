import React, { Component } from 'react';
import axios from 'axios';
import {Buffer} from 'buffer';
class Topform extends Component{
  state = {
	success:false,
	loading:false,
	email: '',
	domain:window.Configs.domain
	//domain: window.location.hostname.replace('www.','')
  }

  handleChange = event => {
    this.setState({ email: event.target.value });
  }

  handleSubmit = event => {
    event.preventDefault();

	this.setState({ loading: true });
	// axios.get('http://api.ipify.org/?format=json').then(response=>{
		// var user_ip = response.data.ip;
		const params = new URLSearchParams();
			params.append('email', this.state.email);
			params.append('domain', this.state.domain);
			//params.append('user_ip', user_ip);
			axios.post('https://www.contrib.com/forms/saveleads', params)
				.then(response => {
					console.log(response);
					this.setState({ success: true });
					this.setState({ loading: false });
				});
	//});
	
  }

  render() {	
	function showAdditionHtml() { return {__html: Buffer.from(window.Configs.additional_html, 'base64').toString('ascii')}}
    return (
      <div>
		{
			!this.state.success ? (
			<form onSubmit={this.handleSubmit}> 
				<div className="input-group mb-2">
					<input value={this.state.email} type="email" name="email" onChange={this.handleChange} className="form-control form-control-lg" placeholder="Email" required/>
					<button type="submit" className="btn btn-lg btn-danger pulse-button">Submit</button>
				</div>
			</form>
			) : null
		}
		<div id='sucess_msg' style={{display: this.state.success ? 'block' : 'none' }}>
			<div className="col-md-12 text-center">
				<h3>Thanks, your spot is reserved!</h3> Share {window.Configs.domain} with you friends to move up in line and reserve your username.
				<div className="addHtml mt-3" dangerouslySetInnerHTML={showAdditionHtml()}></div>
				<div className="followLink mt-3">					
					<form target="_blank" action={"https://www.contrib.com/signup/follow/"+window.Configs.domain} method="post">
						<input type="hidden" id="pemail" name="email_follow" value={this.state.email} />
						<button className="btn btn-warning">Continue to Follow {window.Configs.domain} Brand</button>
					</form>					
				</div>
			</div>
		</div>
		<div id='loading_msg' style={{display: this.state.loading ? 'block' : 'none' }} className="text-center">
			<h3><i className="fas fa-spinner fa-spin"></i></h3>
		</div>
      </div>
    )
  }
}
export default Topform;