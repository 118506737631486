import React, { Component } from 'react';
class Privacy extends Component{
	componentDidMount() {
		console.log('privacy componentDidMount');
		if (typeof window.ReloadPage === 'function') { console.log('fire ReloadPage'); window.ReloadPage();}
	}
	render(){
		return(
			<React.Fragment>
				<style>
				{`
				.lead-top-widget {
					display: none !important;
				}
				.navbar-dark {
						display: none !important;
					}
					.sec-footer-top {
						display: none !important;
					}
					.sec-footer-bottom {
						display: none !important;
					}
				.public-pages {
					min-height: calc(90vh - 50px);
					background-position: 50%;
					background-size: cover;
					background-repeat: no-repeat;
					padding-top: 80px;
					padding-bottom: 50px;
					position: relative;
					}
					.overlay {
					background-color: rgba(0, 0, 0, 0.85);
					bottom: 0;
					left: 0;
					position: absolute;
					right: 0;
					top: 0;
					}
					.jumbotron {
					background: none;
					color: #ffffff;
					padding: 2rem 5rem;
					}
					.about-title {
					font-size: 4rem;
					font-weight: 600;
					}
					.about-left-title {
					font-family: 'Caveat', cursive;
					border-bottom: 1px dashed #ffffff;
					padding-bottom: 1rem;
					}
				`}
				</style>
				<div className="privacy-script"></div>
			</React.Fragment>
		);
	}
}

export default Privacy