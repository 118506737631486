import React, { Component } from 'react';
 import FeaturedSiteList from './components/FeaturedSiteList';
class Brand extends Component {
  render() {
    return (
<div className="section-main">
	<div className="main_container">			
		
		<div className="sec-container bg-white">
			<div className="container">
				<div className="row">
					<div className="col-md-12 text-center">
						<div className="section-title-1">
							<h2 className="sec-title fw-800 text-uppercase">Our Top Brands</h2>
						</div>						
					</div>
					<div className="col-md-12 mt-5">
					 <FeaturedSiteList />
					{/*
						<div className="owl-carousel owl-theme">
						    <div className="item">
							    <img src="https://cdn.vnoc.com/logos/logo-Applications.png" alt="applications.com" title="applications.com" className="img-fluid"></img>
							    <h6>
								Join our exclusive community of like minded people on virtualinterns.com									
							    </h6>
							    <div className="dom-buttons mt-3">
								<span className="btn btn-sm btn-primary">Visit</span>
								<span className="btn btn-sm btn-primary">Details</span>
							    </div>
						    </div>
						    <div className="item">
							    <img src="https://cdn.vnoc.com/logos/logo-virtualinterns.png" alt="applications.com" title="applications.com" className="img-fluid"></img>
							    <h6>
								Join our exclusive community of like minded people on virtualinterns.com									
							    </h6>
							    <div className="dom-buttons mt-3">
								<span  className="btn btn-sm btn-primary">Visit</span>
								<span className="btn btn-sm btn-primary">Details</span>
							    </div>
						    </div>
						    <div className="item">
							    <img src="https://cdn.vnoc.com/logos/logo-codechallenge.png" alt="applications.com" title="applications.com" className="img-fluid"></img>
							    <h6>
								Join our exclusive community of like minded people on virtualinterns.com									
							    </h6>
							    <div className="dom-buttons mt-3">
								<span className="btn btn-sm btn-primary">Visit</span>
								<span className="btn btn-sm btn-primary">Details</span>
							    </div>
						    </div>
						    <div className="item">
							    <img src="https://cdn.vnoc.com/logos/image_startupchallenge-big-300x60.png" alt="applications.com" title="applications.com" className="img-fluid"></img>
							    <h6>
								Join our exclusive community of like minded people on virtualinterns.com									
							    </h6>
							    <div className="dom-buttons mt-3">
								<span className="btn btn-sm btn-primary">Visit</span>
								<span className="btn btn-sm btn-primary">Details</span>
							    </div>
						    </div>
						    <div className="item">
							    <img src="https://cdn.vnoc.com/logos/logo-VentureBook.png" alt="applications.com" title="applications.com" className="img-fluid"></img>
							    <h6>
								Join our exclusive community of like minded people on virtualinterns.com									
							    </h6>
							    <div className="dom-buttons mt-3">
								<span className="btn btn-sm btn-primary">Visit</span>
								<span className="btn btn-sm btn-primary">Details</span>
							    </div>
						    </div>
						    <div className="item">
							    <img src="https://cdn.vnoc.com/logos/logo-new-referral-1.png" alt="applications.com" title="applications.com" className="img-fluid"></img>
							    <h6>
								Join our exclusive community of like minded people on virtualinterns.com									
							    </h6>
							    <div className="dom-buttons mt-3">
								<span className="btn btn-sm btn-primary">Visit</span>
								<span className="btn btn-sm btn-primary">Details</span>
							    </div>
						    </div>
						    <div className="item">
							    <img src="https://cdn.vnoc.com/logos/logo-CookBoard-2.png" alt="applications.com" title="applications.com" className="img-fluid"></img>
							    <h6>
								Join our exclusive community of like minded people on virtualinterns.com									
							    </h6>
							    <div className="dom-buttons mt-3">
								<span className="btn btn-sm btn-primary">Visit</span>
								<span className="btn btn-sm btn-primary">Details</span>
							    </div>
						    </div>
						</div>
					*/}
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
    );
  }
}
 
export default Brand;