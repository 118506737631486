import React from 'react'

function Logo() {
 
    if(window.Configs.logo===''){
		return <h1 className="fw-800 mt-3 text-capitalize">{window.Configs.domain.charAt(0).toUpperCase() + window.Configs.domain.slice(1)}</h1>;
	}else{
		return <div className="sec-logo text-center"><img className="img-fluid logo-mh" src={window.Configs.logo} alt="" /></div>
	}
  
}

export default Logo
