import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Navigation extends Component{
	render(){
		return (
			<React.Fragment>				
				<nav className="navbar navbar-expand-lg navbar-dark bg-dark">
					<div className="container"> 
						<a href={'/'} className="navbar-brand text-capitalize">{window.Configs.domain}</a>
						<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarFramework" aria-controls="navbarFramework" aria-expanded="false" aria-label="Toggle navigation"> 
							<span className="navbar-toggler-icon"></span> 
						</button>
						<div className="collapse navbar-collapse" id="navbarFramework">
							<div className="mr-auto"></div>
							<ul className="navbar-nav">
								<a href={'/'} className="nav-link">Home</a>
								<Link to='/about' className="nav-link">About</Link>
							</ul>							
						</div>
					</div>
				</nav>
			</React.Fragment>
		);
	}
}

export default Navigation;
